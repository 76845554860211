import React, { Fragment } from "react";
import { Grid, Typography, Card, Link } from "@material-ui/core";
import Sidebar from "../components/sidebar";
import { makeStyles } from "@material-ui/core/styles";
import {
  WorkExperience,
  FormalEducation,
  Certification,
  Skills,
} from "../database/data-resume";

const useStyles = makeStyles({
  root: {
    background: "#282c34",
    color: "#fff",
  },
  heading1: {
    color: "#61dafb",
    fontWeight: 800,
    fontSize: 25,
    paddingBottom: 20,
    paddingTop: 20,
  },
  heading2: {
    color: "#fff",
    paddingLeft: 5,
    fontWeight: 800,
  },
  colorPrimary: {
    color: "#61dafb",
  },
  container: {
    margin: 30,
  },
  gridOverflow: {
    overflowY: "scroll",
    height: "100vh",
  },
  cardContainer: {
    width: 200,
    background: "#20232a",
    color: "#fff",
    borderRadius: 12,
    padding: 20,
  },
  cardTitle: {
    fontWeight: 800,
  },
  cardImageContainer: {
    textAlign: "center",
    padding: "20px 0",
  },
  cardImage: {
    borderRadius: 12,
    width: 100,
  },
  cardDate: {
    fontSize: 14,
    color: "#999999",
  },
  skillContainer: {
    background: "#20232a",
    borderRadius: 12,
    padding: 20,
    marginTop: 5,
    justifyContent: 'center',
  },
  skill: {
    background: "none",
    textAlign: "center",
  },
  skillTitle: {
    paddingTop: 10,
    color: "#fff",
    fontWeight: 800,
  },
});

const Heading = (props) => {
  const classes = useStyles();
  return (
    <Typography className={classes.heading1}>
      {props.text1}
      <span className={classes.heading2}>{props.text2}</span>
    </Typography>
  );
};

const CardItem = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <Typography className={classes.cardTitle}>{props.title}</Typography>
      <div className={classes.cardImageContainer}>
        <img src={props.logo} alt={props.alt} className={classes.cardImage} />
      </div>
      <Typography className={classes.cardDate}>{props.date}</Typography>
      <Typography>{props.desc}</Typography>
      <Typography>
        <Link
          href={props.link}
          rel="noopener noreferrer"
          target="_blank"
          className={classes.colorPrimary}
        >
          {props.linkText}
        </Link>
      </Typography>
    </Card>
  );
};

const CardSkill = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.skill}>
      <img src={props.logo} alt={props.alt} style={{ height: 100 }} />
      <Typography className={classes.skillTitle}>{props.title}</Typography>
    </div>
  );
};

const Resume = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={2}>
            <Sidebar />
          </Grid>
          <Grid item xs={10} className={classes.gridOverflow}>
            <div className={classes.container}>
              <Heading text1="Work" text2="Experience" />
              <Grid container spacing={5}>
                {WorkExperience.slice(0).reverse().map((res) => (
                  <Fragment key={res.id}>
                    <Grid item>
                      <CardItem
                        title={res.title}
                        logo={res.logo}
                        alt={res.alt}
                        date={res.date}
                        desc={res.desc}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>

              <Heading text1="My" text2="Certification" />
              <Grid container spacing={5}>
                {Certification.slice(0).reverse().map((res) => (
                  <Fragment key={res.id}>
                    <Grid item>
                      <CardItem
                        title={res.title}
                        logo={res.logo}
                        alt={res.alt}
                        date={res.date}
                        desc={res.desc}
                        link={res.link}
                        linkText="View Certificate"
                      />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>

              <Heading text1="Formal" text2="Education" />
              <Grid container spacing={5}>
                {FormalEducation.slice(0).reverse().map((res) => (
                  <Fragment key={res.id}>
                    <Grid item>
                      <CardItem
                        title={res.title}
                        logo={res.logo}
                        alt={res.alt}
                        date={res.date}
                        desc={res.desc}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>

              <Heading text1="Professional" text2="Skills" />
              <Grid container spacing={5} className={classes.skillContainer}>
                {Skills.map((res) => (
                  <Fragment key={res.id}>
                    <Grid item>
                      <CardSkill
                        logo={res.logo}
                        alt={res.alt}
                        title={res.title}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default Resume;
