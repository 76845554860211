import React, { Fragment } from "react";
import { Grid, Typography, Tooltip, Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { loadCSS } from "fg-loadcss";

import Sidebar from "../components/sidebar";

const useStyles = makeStyles({
  root: {
    background: "#282c34",
    color: "#fff",
  },
  container: {
    textAlign: "center",
    width: 600,
    margin: "auto",
    marginTop: 20,
  },
  text: {
    fontSize: 25,
  },
  socialmedia: {
    display: "flex",
    justifyContent: "space-evenly",
    width: 300,
    margin: "auto",
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  button: {
    marginBottom: 20,
  }
});

const text = [
  {
    id: 1,
    text:
      "Every day, I work and study on front-end projects with the latest language and technology. I always try to experiment with new frameworks and libraries. ",
  },
  {
    id: 2,
    text:
      " I am of the view that every new experience is an opportunity to learn. I really like projects that require me to be able to and acquire new skills to succeed.",
  },
  {
    id: 3,
    text:
      " when I'm not working, I usually read a number of books or articles, go for a walk, or play games to refresh my mind and body.",
  },
];

const socialmedia = [
  {
    id: 1,
    icon: <Icon className="fab fa-facebook-square" />,
    title: "Facebook",
    link: "https://www.facebook.com/iqbalbayhaqi.abdullah",
  },
  {
    id: 2,
    icon: <Icon className="fab fa-instagram" />,
    title: "Instagram",
    link: "https://www.instagram.com/iqballbayhaqi/",
  },
  {
    id: 3,
    icon: <Icon className="fab fa-twitter" />,
    title: "Twitter",
    link: "https://twitter.com/iqballbayhaqi",
  },
  {
    id: 4,
    icon: <Icon className="fab fa-linkedin" />,
    title: "Linkedin",
    link: "https://www.linkedin.com/in/iqballbayhaqi",
  },
  {
    id: 5,
    icon: <Icon className="fab fa-github" />,
    title: "Github",
    link: "https://github.com/iqballbayhaqi",
  },
];

const About = () => {
  const classes = useStyles();

  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={2}>
            <Sidebar />
          </Grid>
          <Grid item xs={10}>
            <div className={classes.container}>
              <Typography variant="h3" style={{ fontWeight: 800 }}>
                About Me ?
              </Typography>
              <div style={{ marginTop: 20 }}>
                {text.map((res) => (
                  <Fragment key={res.id}>
                    <Typography className={classes.text}>{res.text}</Typography>
                    <br />
                  </Fragment>
                ))}
              </div>

              <Button
                variant="contained"
                className={classes.button}
                startIcon={<GetAppIcon />}
                href="https://drive.google.com/file/d/18xiM5mom94winsp31IqY71DMWVzMlrWA/view?usp=sharing"
              >
                Download CV
              </Button>
              
              <div className={classes.socialmedia}>
                {socialmedia.map((res) => (
                  <Fragment key={res.id}>
                    <Tooltip title={res.title} placement="bottom">
                      <a
                        href={res.link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.link}
                      >
                        {res.icon}
                      </a>
                    </Tooltip>
                  </Fragment>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default About;
