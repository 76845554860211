import React, { Fragment, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import Typewriter from "typewriter-effect";
import { makeStyles } from "@material-ui/core/styles";
import WaterWave from "react-water-wave";
import backgroundImage from "../assets/images/bg.jpg";
import "../styles/bounce.css";
import Navbar from "../components/navbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Contact from "./contact";

import Sidebar from "../components/sidebar";
import Portfolio from "./portfolio";

const Home = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const isPhone = useMediaQuery("(max-width:600px)");
  const refPortfolio = useRef(null);

  return (
    <Fragment>
      <div
        className={classes.root}
        style={{
          height: window.screen.height,
          backgroundPositionX: isPhone && -300,
          display: "flex",
        }}
        id="home"
      >
        {/* <WaterWave
          style={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            display: "flex",
          }}
          dropRadius={20}
          perturbance={10}
          resolution={512}
        > */}
        {/* {(methods) => ( */}
        <Fragment>
          <Navbar />
          {!isPhone && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 30,
              }}
            >
              <div style={{ width: 30 }}>
                <a
                  href="https://www.facebook.com/iqbalbayhaqi.abdullah/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <FacebookIcon className={classes.iconSosmed} />
                </a>
                <a
                  href="https://www.instagram.com/iqballbayhaqi/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <InstagramIcon className={classes.iconSosmed} />
                </a>
                <a
                  href="https://twitter.com/iqballbayhaqi"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <TwitterIcon className={classes.iconSosmed} />
                </a>
                <a
                  href="https://www.linkedin.com/in/iqballbayhaqi/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <LinkedInIcon className={classes.iconSosmed} />
                </a>
                <a
                  href="mailto:baihaqiiqbal323@gmail.com"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <MailOutlineIcon className={classes.iconSosmed} />
                </a>
              </div>
            </div>
          )}
          <div className={classes.container}>
            <div>
              <Typography
                className={classes.name}
                style={{ lineHeight: isPhone ? 1 : 0.5 }}
              >
                Iqbal Baihaqi
              </Typography>
              <Typography
                className={classes.name}
                style={{
                  lineHeight: isPhone && 1,
                  marginBottom: isPhone && 20,
                }}
              >
                Abdulah
              </Typography>
              <Typography className={classes.center} style={{ fontSize: 20 }}>
                <span>I'am &nbsp;</span>
                <Typewriter
                  options={{
                    strings: [
                      "Frontend Developer",
                      "Backend Developer",
                      "Mobile Developer",
                      "Unity Game Developer",
                      "Fullstack Developer",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Typography>
            </div>
            {/* <div className={classes.expandMore}>
              <div className="bounce">
                <ExpandMoreIcon
                  onClick={() => refPortfolio.current.scrollIntoView()}
                  style={{ height: 60, width: "auto", cursor: "pointer" }}
                />
              </div>
            </div> */}
          </div>
        </Fragment>
        {/*  )} */}
        {/* </WaterWave> */}
      </div>

      <div style={{ marginTop: -190 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#282c34"
            fill-opacity="1"
            d="M0,160L60,144C120,128,240,96,360,90.7C480,85,600,107,720,117.3C840,128,960,128,1080,133.3C1200,139,1320,149,1380,154.7L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div style={{ marginTop: -10, scrollMarginTop: 100 }} ref={refPortfolio}>
        <Portfolio />
        <Contact />
      </div>
    </Fragment>
  );
};

export default Home;

const useStyles = makeStyles({
  root: {
    background: "#282c34",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: `url(${backgroundImage})`,
    color: "#fff",
    boxShadow: "inset 0 0 0 2000px #282c3487",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: 66,
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 40,
  },
  container: {
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconSosmed: {
    width: "100%",
    margin: "10px 0",
    cursor: "pointer",
    transition: "0.5s",
    color: "#ffffff",
    "&:hover": {
      color: "#1594AC",
      transition: "0.5s",
    },
  },
  expandMore: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    marginBottom: 20,
    textAlign: "center",
  },
});
