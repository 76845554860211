import React, { Fragment, useState } from "react";
import {
  Grid,
  Typography,
  Modal,
  Backdrop,
  Link,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useSpring, animated } from "@react-spring/web";
import Navbar from "../components/navbar";
import backgroundImage from "../assets/images/bg.jpg";
import placeholderImage from "../assets/images/placeholder.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PortfolioData from "../database/data-portfolio";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#282c34",
    color: "#fff",
    height: "fit-content",
    background: "#282c34",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // backgroundImage: `url(${backgroundImage})`,
    boxShadow: "inset 0 0 0 2000px #282c3487",
  },
  container: {
    // paddingTop: 100,
  },
  cardContainer: {
    background: "#20232a",
    borderRadius: 12,
    textAlign: "center",
    width: 300,
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    height: 200,
    borderRadius: "12px 12px 0 0",
    objectFit: "cover",
  },
  imageWrapper: {
    position: "relative",
    transition: ".3s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      transition: ".3s ease-in-out",
      borderRadius: 12,
    },
  },
  cardTitle: {
    fontSize: 20,
    paddingTop: 20,
  },
  cardSubtitle: {
    paddingBottom: 20,
    color: "#999999",
  },
  title: {
    fontWeight: 800,
    textAlign: "center",
    marginBottom: 50,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  modalmage: {
    width: 700,
  },
  modalhead1: {
    fontWeight: 800,
  },
  mainStack: {
    width: 40,
    height: 40,
    objectFit: "contain",
    position: "absolute",
    right: 0,
    top: 0,
    marginRight: -15,
    marginTop: -15,
  },
  space: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const Portfolio = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState({
    id: null,
    image: "",
    title: "",
    role: "",
    client: "",
    link: "",
    stacks: [],
  });
  const [selectedMenu, setMenuSelected] = useState("all");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStateModal = (res) => {
    setModal({
      id: res.id,
      image: res.image,
      title: res.title,
      role: res.role,
      client: res.client,
      link: res.link,
      stacks: res.stacks,
    });
    console.log(modal);
    handleOpen();
  };

  return (
    <Fragment>
      <div
        className={classes.root}
        style={{
          height: isPhone && "100%",
          backgroundPositionX: isPhone && -300,
        }}
      >
        {/* <Navbar /> */}
        <div className={classes.container} id="portfolio">
          <Typography variant="h3" className={classes.title}>
            Portfolio
          </Typography>

          <div
            style={{
              justifyContent: "center",
              paddingBottom: 20,
              width: "100%",
              display: "flex",
            }}
            className={classes.space}
          >
            <Button
              variant={selectedMenu === "all" ? "contained" : "outlined"}
              color={selectedMenu === "all" ? "default" : "inherit"}
              onClick={() => setMenuSelected("all")}
            >
              ALL
            </Button>
            <Button
              variant={selectedMenu === "web" ? "contained" : "outlined"}
              color={selectedMenu === "web" ? "default" : "inherit"}
              onClick={() => setMenuSelected("web")}
            >
              WEB
            </Button>
            <Button
              variant={selectedMenu === "mobile app" ? "contained" : "outlined"}
              color={selectedMenu === "mobile app" ? "default" : "inherit"}
              onClick={() => setMenuSelected("mobile app")}
            >
              MOBILE APP
            </Button>
            <Button
              variant={
                selectedMenu === "game mobile" ? "contained" : "outlined"
              }
              color={selectedMenu === "game mobile" ? "default" : "inherit"}
              onClick={() => setMenuSelected("game mobile")}
            >
              GAME MOBILE
            </Button>
          </div>

          <Grid
            container
            spacing={!isPhone && 6}
            style={{
              justifyContent: "center",
              paddingBottom: 20,
              width: "100%",
            }}
          >
            {PortfolioData.filter(project => selectedMenu !== "all" ? project.category === selectedMenu : project).slice(0)
              .reverse()
              .map((res) => (
                <Grid item key={res.id}>
                  <div
                    className={classes.cardContainer}
                    onClick={() => handleStateModal(res)}
                    style={{
                      marginBottom: isPhone && 20,
                    }}
                  >
                    <div className={classes.imageWrapper}>
                      <img
                        className={classes.cardImage}
                        src={res.image}
                        alt={res.title}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = placeholderImage;
                        }}
                      />
                      <img
                        src={res.main_stack}
                        alt="main stack"
                        className={classes.mainStack}
                      />
                    </div>
                    <Typography className={classes.cardTitle}>
                      {res.title}
                    </Typography>
                    <Typography className={classes.cardSubtitle}>
                      {res.role}
                    </Typography>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <img
              src={modal.image}
              alt={modal.title}
              className={classes.modalmage}
              style={{ width: isPhone && "100%" }}
            />
            <Grid container style={{ textAlign: "center", paddingTop: 30 }}>
              <Grid item xs={6}>
                <Typography className={classes.modalhead1}>Role</Typography>
                <Typography>{modal.role}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.modalhead1}>Client</Typography>
                <Typography>{modal.client}</Typography>
              </Grid>
            </Grid>

            <Typography
              className={classes.modalhead1}
              style={{ textAlign: "center" }}
            >
              Stacks
            </Typography>
            <Grid
              container
              spacing={1}
              style={{ justifyContent: "center", paddingTop: 10 }}
            >
              {modal.stacks.map((res) => (
                <Grid item>
                  <img src={res} height={50} alt="" />
                </Grid>
              ))}
            </Grid>

            <div style={{ textAlign: "center", paddingTop: 20 }}>
              <Link
                target="_blank"
                href={modal.link}
                style={{ color: "#333333", fontWeight: 600 }}
              >
                {"Visit Site >"}
              </Link>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
};

export default Portfolio;
