import React, { Fragment, useState } from "react";
import {
  Typography,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Router, Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 10,
    backdropFilter: "saturate(180%) blur(10px)",
    background: "rgba(0,0,0,.7)",
    left: 0,
    right: 0,
  },
  rootMobile: {
    position: "fixed",
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 2,
    backdropFilter: "saturate(180%) blur(10px)",
    background: "rgba(0,0,0,.7)",
    left: 0,
    right: 0,
  },
  name: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  button: {
    textTransform: "none",
    color: "#ffffff",
  },
  menuContainer: {
    display: "flex",
    color: "#ffffff",
    alignItems: "center",
  },
  menu: {
    cursor: "pointer",
  },
  currentMenu: {
    cursor: "pointer",
    borderBottom: "2px solid #1594AC",
    padding: "8px 12px",
  },
  itemMenu: {
    background: "#202020a6",
  },
  collapse: {
    position: "fixed",
    width: "100%",
    zIndex: 1,
    paddingTop: 70,
  },
});

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const isPhone = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const path = window.location.pathname;

  return (
    <Fragment>
      {isPhone ? (
        <div>
          <div className={classes.rootMobile}>
            <Typography variant="h5" className={classes.name}>
              Balee
            </Typography>
            <MenuIcon onClick={handleClick} />
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={classes.collapse}>
              {/* <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.itemMenu}
                  onClick={() => history.push("/")}
                >
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem
                  button
                  className={classes.itemMenu}
                  onClick={() => history.push("/portfolio")}
                >
                  <ListItemText primary="Portfolio" />
                </ListItem>
              </List>
              <ListItem
                button
                className={classes.itemMenu}
                onClick={() => history.push("/contact")}
              >
                <ListItemText primary="Contact" />
              </ListItem> */}
              <ListItem
                button
                className={classes.itemMenu}
              >
                <Button
                  variant="outlined"
                  className={classes.button}
                  startIcon={<InsertDriveFileIcon />}
                  href="https://drive.google.com/file/d/1fYNgTOadPxvXuhJI9tIIDJxyhwzQDCww/view?usp=sharing"
                  target="_blank"
                >
                  Download CV
                </Button>
              </ListItem>
            </div>
          </Collapse>
        </div>
      ) : (
        <div className={classes.root}>
          <Typography variant="h5" className={classes.name}>
            Balee
          </Typography>
          <div className={classes.menuContainer}>
            {/* <Link to="/" style={{ color: "#ffffff", textDecoration: "none" }}>
              <Typography
                className={path === "/" ? classes.currentMenu : classes.menu}
              >
                Home
              </Typography>
            </Link>
            <Link
              to="/portfolio"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              <Typography
                className={
                  path === "/portfolio" ? classes.currentMenu : classes.menu
                }
                style={{ marginLeft: 20 }}
              >
                Portfolio
              </Typography>
            </Link>
            <Link
              to="/contact"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              <Typography
                className={
                  path === "/contact" ? classes.currentMenu : classes.menu
                }
                style={{ marginLeft: 20 }}
              >
                Contact
              </Typography>
            </Link> */}
          </div>
          <div>
            <Button
              variant="outlined"
              className={classes.button}
              startIcon={<InsertDriveFileIcon />}
              href="https://drive.google.com/file/d/1fYNgTOadPxvXuhJI9tIIDJxyhwzQDCww/view?usp=sharing"
              target="_blank"
            >
              Download CV
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Navbar;
