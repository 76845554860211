import React, { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../assets/images/bg.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Navbar from "../components/navbar";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#282c34",
    color: "#fff",
    height: window.screen.height,
    background: "#282c34",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // backgroundImage: `url(${backgroundImage})`,
    boxShadow: 'inset 0 0 0 2000px #282c3487',
  },
  container: {
    textAlign: "center",
    width: 600,
    margin: "auto",
    paddingTop: 100,
  },
  containerMobile: {
    textAlign: "center",
    width: '50%',
    margin: "auto",
    paddingTop: 100,
  },
});

const Contact = () => {
  const classes = useStyles();
  const isPhone = useMediaQuery("(max-width:600px)");

  return (
    <Fragment>
      <div className={classes.root} style={{backgroundPositionX: isPhone && -300}}>
        <Navbar />
        <div className={isPhone ? classes.containerMobile : classes.container} id="contact">
          <Typography variant="h3" style={{ fontWeight: 800 }}>
            Contact
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isPhone ? 50 : 150,
            }}
          >
            <div>
              <Typography style={{ textAlign: "left", fontSize: !isPhone && 25 }}>
                You can reach me for an inquiry or just to say hi.
              </Typography>
              <div style={{ marginTop: 20, textAlign: "left" }}>
                <Typography style={{ fontSize: !isPhone && 25 }}>
                  Email: baihaqiiqbal323@gmail.com
                </Typography>
                <Typography style={{ fontSize: !isPhone && 25 }}>
                  Github: https://github.com/iqballbayhaqi
                </Typography>
                <Typography style={{ fontSize: !isPhone && 25 }}>
                  Linkedin: https://www.linkedin.com/in/iqballbayhaqi
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
