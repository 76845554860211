import MovieKita from "../assets/images/portfolio/moviekita.png"
import IndonesiaDesign from "../assets/images/portfolio/indonesiadesign.png"
import RekatAnugerah from "../assets/images/portfolio/rekatanugerah.png"
import Idejualan from "../assets/images/portfolio/idejualan.png"
import MeGames from "../assets/images/portfolio/megame.png"

import graphQLImage from "../assets/images/icons/graphql.png";
import jestImage from "../assets/images/icons/jest.png";
import materialUiImage from "../assets/images/icons/material-ui.png";
import mongoDbImage from "../assets/images/icons/mongo-db.png";
import nodeJsImage from "../assets/images/icons/node-js.png";
import puppeteer from "../assets/images/icons/puppeteer.png";
import reactRouterImage from "../assets/images/icons/react-router.png";
import reactImage from "../assets/images/icons/react.png";
import reduxImage from "../assets/images/icons/redux.png";
import styledComponentsImage from "../assets/images/icons/styled-components.png";
import vercelImage from "../assets/images/icons/vercel.png";
import vueImage from "../assets/images/icons/vue.png";
import wordpressImage from "../assets/images/icons/wordpress.png";
import bootstrapImage from "../assets/images/icons/bootstrap.png";
import firebaseImage from "../assets/images/icons/firebase.png";
import expoImage from "../assets/images/icons/expo.png";
import unityImage from "../assets/images/icons/unity.png";
import nextjsImage from "../assets/images/icons/nextjs.png";
import hybrissImage from "../assets/images/icons/hybris.png";
import antdesignImage from "../assets/images/icons/ant-design.png";

const PortfolioData = [
  {
    id: 1,
    image: "https://i.ibb.co/prxfntP/movie-kita-rd9ymm97l-iqballbayhaqi-vercel-app-1080p.png",
    title: "MovieKita",
    role: "Frontend Developer",
    client: "Personal",
    link: "https://movie-kita.vercel.app/",
    main_stack: reactImage,
    stacks: [reactImage, reactRouterImage, styledComponentsImage, vercelImage],
    category: "web"
  },
  {
    id: 2,
    image: IndonesiaDesign,
    title: "Indonesia Design",
    role: "Frontend Developer",
    client: "Indonesia Design",
    link: "http://indonesiadesign.com/",
    main_stack: reactImage,
    stacks: [reactImage, reactRouterImage, styledComponentsImage],
    category: "web"
  },
  {
    id: 3,
    image: RekatAnugerah,
    title: "PT Rekat Anugerah Lestari",
    role: "Fullstack Wordpress Developer",
    client: "PT Rekat Anugerah Lestari",
    link: "http://rekatanugerah.com/",
    main_stack: wordpressImage,
    stacks: [wordpressImage],
    category: "web"
  },
  {
    id: 4,
    image: Idejualan,
    title: "Idejualan",
    role: "Frontend Developer",
    client: "PT. IDE JUALAN CREATIVE",
    link: "https://app.idejualan.com/",
    main_stack: reactImage,
    stacks: [reactImage, reactRouterImage, materialUiImage, vercelImage],
    category: "web"
  },
  {
    id: 5,
    image: "https://i.imgur.com/WHFQPxn_d.webp?maxwidth=760&fidelity=grand",
    title: "Animekudesu",
    role: "Full Stack Developer",
    client: "Personal",
    link: "https://animekudesu.web.app/",
    main_stack: reactImage,
    stacks: [reactImage, reduxImage, reactRouterImage, materialUiImage, nodeJsImage, firebaseImage],
    category: "web"
  },
  {
    id: 6,
    image: "https://i.imgur.com/Hp7CcpS_d.webp?maxwidth=760&fidelity=grand",
    title: "Tanjiro Kanban App",
    role: "Full Stack Developer",
    client: "Personal",
    link: "https://tanjiro-kanban.web.app/",
    main_stack: vueImage,
    stacks: [vueImage, nodeJsImage, bootstrapImage, mongoDbImage, jestImage, firebaseImage],
    category: "web"
  },
  {
    id: 7,
    image: "https://i.imgur.com/3XW5mEE_d.webp?maxwidth=760&fidelity=grand",
    title: "Sudoku Master",
    role: "Mobile Developer",
    client: "Personal",
    link: "https://expo.io/artifacts/547e9ebb-2e0f-4092-ba89-1dc470799da6",
    main_stack: reactImage,
    stacks: [reactImage, expoImage],
    category: "mobile app"
  },
  {
    id: 8,
    image: "https://i.imgur.com/HHDNGsw_d.webp?maxwidth=760&fidelity=grand",
    title: "JobScrapper",
    role: "Full Stack Developer",
    client: "Personal",
    link: "https://jobscrapper.vercel.app/",
    main_stack: reactImage,
    stacks: [reactImage, reduxImage, reactRouterImage, bootstrapImage, nodeJsImage, puppeteer, graphQLImage, jestImage, mongoDbImage, vercelImage],
    category: "web"
  },
  {
    id: 9,
    image: "https://i.imgur.com/8ZX7ost.png?maxwidth=760&fidelity=grand",
    title: "meGames",
    role: "Unity Game Developer",
    client: "PT Aku Cinta Memiles",
    link: "https://play.google.com/store/apps/details?id=com.megames.app&hl=en_US&gl=US",
    main_stack: unityImage,
    stacks: [unityImage],
    category: "game mobile"
  },
  {
    id: 10,
    image: "https://i.imgur.com/rXm8LcT.png?maxwidth=760&fidelity=grand",
    title: "HaiDafam Hotels",
    role: "Frontend Developer",
    client: "PT Dafam Hotel Management",
    link: "https://m.haidafam.com/",
    main_stack: reactImage,
    stacks: [reactImage, nextjsImage, styledComponentsImage, vercelImage],
    category: "web"
  },
  {
    id: 11,
    image: "https://i.imgur.com/uZ0IQ9Z.png?maxwidth=760&fidelity=grand",
    title: "Maca Qur'an",
    role: "Frontend Developer",
    client: "Personal",
    link: "https://maca-quran.vercel.app/",
    main_stack: reactImage,
    stacks: [reactImage, materialUiImage, vercelImage],
    category: "web"
  },
  {
    id: 12,
    image: "https://i.ibb.co/mygnWgk/www-mobbi-id-1080p.png",
    title: "Mobbi",
    role: "Frontend Developer",
    client: "PT Serasi Autoraya",
    link: "https://www.mobbi.id/",
    main_stack: reactImage,
    stacks: [reactImage, hybrissImage, antdesignImage, nextjsImage],
    category: "web"
  }
]

export default PortfolioData;