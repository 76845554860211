const WorkExperience = [
  {
    id: 1,
    title: "Freelance Frontend Web Developer",
    logo:
      "https://i7.pngguru.com/preview/15/884/1016/computer-icons-web-browser-world-wide-web.jpg",
    alt: "web",
    date: "August 2019 - Present",
    desc:
      "Focusing on the front-end, I already have skills such as HTML, CSS, JavaScript, and some frameworks such as ReactJS, Bootstrap, and others.",
  },
  {
    id: 2,
    title: "PT Rekat Anugerah Lestari - rekatanugerah.com",
    logo:
      "http://rekatanugerah.com/wp-content/uploads/2020/02/Main-Logo-RAL-300x130.png",
    alt: "PT Rekat Anugerah Lestari",
    date: "2020",
    desc:
      "Build Website with Wordpress, Add some Plugins, and Install them on the Server",
  },
  {
    id: 3,
    title: "KodingWorks - Remote Internship",
    logo:
      "https://media-exp1.licdn.com/dms/image/C560BAQGusy1FsUixrg/company-logo_200_200/0?e=2159024400&v=beta&t=rIEMHNNTP9Ej6HmF1Dwi8vacSXS-UKxk2Ce9GYIvbXk",
    alt: "koding works",
    date: "2020 - Present",
    desc:
      "Collaborate with the Team Remotely to Create Client Websites with Targeted Vision.",
  },
  {
    id: 4,
    title: "Idejualan - idejualan.now.sh",
    logo:
      "https://idejualan.now.sh/static/media/logo.70cc283e.svg",
    alt: "idejualan",
    date: "2020 - Present",
    desc:
      "working as a frontend developer, I create a website in accordance with the design provided and implement it with a database",
  },
];

const FormalEducation = [
  {
    id: 1,
    title: "SMK Ma'arif NU 2 Ajibarang",
    logo: "https://nahdlatululama.id/wp-content/uploads/2017/05/images-36.jpg",
    alt: "SMK Ma'arif NU 2 Ajibarang",
    date: "2016 - 2019",
    desc:
      "Join the OSIS and Scout organizations to foster the personality of leaders who are firm and responsible",
  },
];

const Certification = [
  {
    id: 1,
    title: "Belajar Dasar Pemrograman Web - Dicoding",
    logo:
      "https://d17ivq9b7rppb3.cloudfront.net/original/event/diskusi_online_kelas_academy_menjadi_android_developer_expert_logo_140318105211.png",
    alt: "Dicoding Belajar Dasar Pemrograman Web",
    date: "07 Oct 2019 - 07 Oct 2022",
    desc:
      "learn the basics of HTML, CSS, and JavaScript which are the main foundation for becoming a Web Developer",
    link:
      "https://drive.google.com/file/d/1OIi3QEfFI0YwhovubOeL4j7r14us4rPk/view?usp=sharing",
  },
  {
    id: 2,
    title: "Belajar Dasar-Dasar Azure Cloud - Dicoding",
    logo:
      "https://d17ivq9b7rppb3.cloudfront.net/original/event/diskusi_online_kelas_academy_menjadi_android_developer_expert_logo_140318105211.png",
    alt: "Dicoding Belajar Dasar-Dasar Azure Cloud",
    date: "13 Nov 2019 - 13 Nov 2022",
    desc:
      "understand the principles and concepts of cloud computing with the introduction of services from Microsoft Azure according to AZ-900 certification requirements",
    link:
      "https://drive.google.com/file/d/1wqU5FNPdh_R71ioT2z9_vx2_VmVMPV6q/view?usp=sharing",
  },
  {
    id: 3,
    title: "Membangun Progressive Web Apps - Dicoding",
    logo:
      "https://d17ivq9b7rppb3.cloudfront.net/original/event/diskusi_online_kelas_academy_menjadi_android_developer_expert_logo_140318105211.png",
    alt: "Dicoding Membangun Progressive Web Apps",
    date: "23 Dec 2019 - 23 Dec 2022",
    desc:
      "learn single page application strategies, offline caching, and push notifications so that the web has functions like a native application",
    link:
      "https://drive.google.com/file/d/1qa3H94vC6tmbKhiI2ojrM6AeFBnXGCki/view?usp=sharing",
  },
];

const Skills = [
  {
    id: 1,
    logo: "https://pngimage.net/wp-content/uploads/2018/06/logo-html-png-3.png",
    alt: "HTML",
    title: "HTML 5",
  },
  {
    id: 2,
    logo:
      "https://cdn.clipart.email/4fa356f49dc33bfe0096cb52c0c28551_its-sage_1135-1269.png",
    alt: "CSS 3",
    title: "CSS 3",
  },
  {
    id: 3,
    logo:
      "https://cdn.clipart.email/219a9f2a1941e9767b17ce98eae4a42f_vector-javascript-picture-2114926-vector-javascript_350-350.png",
    alt: "Javascript",
    title: "Javascript",
  },
  {
    id: 4,
    logo:
      "https://cdn.clipart.email/7d11bb7d1b1fa2655713bb01aeddbc6a_vacasa-executive-dashboard-zerrtech_1000-1000.png",
    alt: "ReactJs",
    title: "ReactJS",
  },
  {
    id: 5,
    logo: "https://cdn.iconscout.com/icon/free/png-512/bootstrap-226077.png",
    alt: "Bootstrap 4",
    title: "Bootstrap 4",
  },
  {
    id: 6,
    logo: "https://material-ui.com/static/logo.png",
    alt: "Material UI",
    title: "Material UI",
  },
  {
    id: 7,
    logo: "https://colinstodd.com/images/posts/matcss-min.png",
    alt: "Materialize",
    title: "Materialize",
  },
  {
    id: 8,
    logo: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
    alt: "Git Versioning",
    title: "Git Versioning",
  },
  {
    id: 9,
    logo:
      "https://pngimage.net/wp-content/uploads/2018/06/node-js-logo-png-1.png",
    alt: "NodeJS",
    title: "NodeJs",
  },
  {
    id: 10,
    logo: "https://www.logo.wine/a/logo/MongoDB/MongoDB-Logo.wine.svg",
    alt: "MongoDB",
    title: "MongoDB",
  },
  {
    id: 11,
    logo:
      "https://www.resourcifi.com/wp-content/themes/resourcifi-child/img/express-min.png",
    alt: "ExpressJS",
    title: "ExpressJS",
  },
  {
    id: 12,
    logo:
      "https://cdn.clipart.email/7d11bb7d1b1fa2655713bb01aeddbc6a_vacasa-executive-dashboard-zerrtech_1000-1000.png",
    alt: "React Native",
    title: "React Native",
  },
  {
    id: 13,
    logo: "https://www.designer.io/wp-content/uploads/2019/02/gravit-logo-2000x2000.png",
    alt: "Gravit Designer",
    title: "Gravit Designer",
  }
];

export { WorkExperience, FormalEducation, Certification, Skills };
