import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import ContactsIcon from "@material-ui/icons/Contacts";
import PhotoProfile from "../assets/images/foto-profile.jpeg";
import Tilt from "react-vanilla-tilt";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    background: "#20232a",
    color: "#fff",
    height: "100vh",
    paddingLeft: 30,
  },
  menu: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0",
  },
  text: {
    paddingLeft: 10,
    cursor: "pointer",
    color: "ffff",
    "&:hover": {
      color: "#61dafb",
    },
  },
  image: {
    width: 150,
    margin: "20px 0",
    borderRadius: 12,
  },
  Tilt: {
    width: "100% !important",
    padding: "0 !important",
    margin: "0 !important",
    background: "none !important",
  },
});

const MenuLink = (props) => {
  return (
    <Link to={props.to} style={{ textDecoration: "none", color: "#fff"}}>
      {props.text}
    </Link>
  );
};

const Menu = [
  {
    id: 1,
    text: <MenuLink to="/" text="Home" />,
    icon: <HomeIcon />,
  },
  {
    id: 2,
    text: <MenuLink to="/about" text="About" />,
    icon: <InfoIcon />,
  },
  {
    id: 3,
    text: <MenuLink to="/resume" text="Resume" />,
    icon: <AssignmentIcon />,
  },
  {
    id: 4,
    text: <MenuLink to="/portfolio" text="Portfolio" />,
    icon: <LaptopMacIcon />,
  },
  {
    id: 5,
    text: <MenuLink to="/contact" text="Contact" />,
    icon: <ContactsIcon />,
  },
];

const Sidebar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Tilt className={classes.Tilt}>
        <img src={PhotoProfile} alt="" className={classes.image} />
      </Tilt>
      {Menu.map((res) => (
        <div className={classes.menu} key={res.id}>
          <span style={{ color: "#61dafb" }}>{res.icon}</span>
          <span>
            <Typography variant="h5" className={classes.text}>
              {res.text}
            </Typography>
          </span>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
